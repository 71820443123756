import React, { useState, useEffect } from 'react';
import useStyles from './style';
import { Alert } from 'components';
import {
	Button, Card, CircularProgress
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import AddIcon from '@material-ui/icons/Add';
import { Breadcrumb } from 'components';
import { SortTable, SingleSelect, DeleteModal } from './components';
import rehabitation_center from '../../apis/rehabitation-center';

import EXCEL from 'js-export-xlsx';
import exportFilename from "../../helpers/exportFilename";

const OrkList = props => {
	const { children } = props;
	const { history } = props;
	const [sortOption, setSortOption] = useState({ sortBy: 0, sortOrder: "asc", sortLabel: "Kliknij aby posortować kolumny rosnąco" });
	const [countList, setCountList] = useState([25, 50, 100]);
	const [selectedCount, setSelectedCount] = useState(25);
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [searchId, setSearchId] = useState('');
	const [searchName, setSearchName] = useState('');
	const [openModal, setOpenModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(-1);
	const classes = useStyles();
	const breadcrumbs = [{ active: true, href: '/ork_list', label: 'Finanse' }, { active: false, label: 'Lista Ośrodków Rehabilitacji Kompleksowej' }];
	const [hasAlert, setHasAlert] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [message, setMessage] = useState('');
	const [progressStatus, setProgressStatus] = useState(false);

	useEffect(() => {
		handleSearch();
	}, []);

	useEffect(() => {
		handleSearch();
	}, [sortOption, page]);

	useEffect(() => {
		handleSearch();
		setPage(1);
	}, [selectedCount, searchId, searchName]);

	const requestSort = (pSortBy) => {
		var sortOrder = "asc";
		var sortLable = "Kliknij aby posortować kolumny rosnąco";
		if (pSortBy === sortOption.sortBy) {
			sortOrder = (sortOption.sortOrder === "asc" ? "desc" : "asc");
			sortLable = (sortOption.sortOrder === "asc" ? "Kliknij aby posortować kolumny malejąco" : "Kliknij aby posortować kolumny rosnąco");
		}
		setSortOption({ sortBy: pSortBy, sortOrder: sortOrder, sortLabel:  sortLable})
	}

	const handleSearch = () => {
		rehabitation_center
			.getListByOption(sortOption.sortBy, sortOption.sortOrder, selectedCount, page, searchId, searchName)
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else {
					if (response.code === 200) {
						setData(response.data.rehabitation_centers);
						setTotal(response.data.count);
					}
				}
			})
	}

	const handleExport = () => {
		rehabitation_center
			.getListByOption(sortOption.sortBy, sortOption.sortOrder, total, page, searchId, searchName)
			.then(response => {
				if (response.code === 401) {
					history.push('/login');
				} else {
					if (response.code === 200) {
						let _data = response.data.rehabitation_centers;
						let export_data = [];
						for (let i = 0; i < _data.length; i++) {
							let item = [];
							item.push(_data[i].id);
							item.push(_data[i].name);
							export_data.push(item);
						}
						EXCEL.outPut({
							header: ['ID', 'Nazwa ośrodka'],
							data: export_data,
							name: exportFilename('Lista ORK', [
								['ID', searchId],
								['Nazwa ośrodka', searchName],
							]),
						})
					}
				}
			})

	}

	const handlePaginationLabel = (type, page, selected) => {
		if (type === 'first') {
			return 'Przejdź do pierwszej strony'
		}
		if (type === 'previous') {
			return 'Wróć do poprzedniej strony';
		}
		if (type === 'next') {
			return 'Przejdź do następnej strony';
		}
		if (type === 'last') {
			return 'Przejdź do ostatniej strony';
		}
		return `Strona ${page}`;
	}

	return (
		<div className={classes.public}>
			<div className={classes.controlBlock}>
				<Button id="main" title="wielkość pliku poniżej 1 megabajt" variant="outlined" color="secondary" className={classes.btnExport} onClick={handleExport}>
					Eksport listy do XLS
				</Button>
			</div>
			<div className={classes.divide} />
			<div className={classes.filter}>
				<Breadcrumb list={breadcrumbs} />
				<div className={classes.rowsBlock}>
					<div>Pokaż:</div>
					<SingleSelect value={selectedCount} handleChange={setSelectedCount} list={countList} />
					<div>pozycji</div>
				</div>
			</div>
			<Alert
				hasAlert={hasAlert}
				setHasAlert={setHasAlert}
				isSuccess={isSuccess}
				message={message}
			/>
			<div className={classes.tableContainer}>
				<Card className={classes.table}>
					<SortTable
						rows={data}
						requestSort={requestSort}
						sortOrder={sortOption.sortOrder}
						sortBy={sortOption.sortBy}
						sortLabel={sortOption.sortLabel}
						searchId={searchId}
						setSearchId={setSearchId}
						searchName={searchName}
						setSearchName={setSearchName}
					/>
					{
						// total > selectedCount &&
						<div className={classes.pagination}>
							<div aria-live="polite" className={classes.totalCnt} aria-atomic="true"> Ilość rekordów: {total} </div>
							<Pagination
								className={classes.pagenation_class}
								count={total % selectedCount == 0 ? total / selectedCount : parseInt(total / selectedCount) + 1}
								onChange={(e, page) => { setPage(page) }}
								page={page}
								getItemAriaLabel={handlePaginationLabel}
								showFirstButton
								showLastButton
								aria-label="Przejdź do następnych stron wyników wyszukiwania wybierając intersująca cię stronę" />
						</div>
					}
				</Card>
			</div>
		</div>
	);
};

export default OrkList;
