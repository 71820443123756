import moment from "moment";

export default (filename = 'export', filters = []) => {
	filters.filter(v => v[1]).forEach(([label, value, list], i) => {
		filename += ', ' + label;
		if (typeof value === 'object') {
			if (value.from) {
				filename += ' od ' + moment(value.from).format('DD-MM-YYYY');
			}
			if (value.to) {
				filename += ' do ' + moment(value.to).format('DD-MM-YYYY');
			}
		} else {
			if (list) {
				const found = list.find(item => item.id + '' === value);
				if (found) {
					filename += ' ' + found.name;
				}
			} else {
				filename += ` ${value}`;
			}
		}
	});

	return filename;
}
